// @ts-nocheck
import React, { useCallback, useContext, useState } from "react";
// lib
import i18n from "i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// shared-comp
import HeaderMarquee from "../../components/shared_comp/HeaderMarquee";
// images
import pointIcon from "../../asset/image/point-image.png";
import HeaderIcon from "../../asset/image/auland.png";
import MMFlag from "../../asset/image/localization/mm.png";
import UKFlag from "../../asset/image/localization/uk.png";
import ChinaFlag from "../../asset/image/localization/china.png";
import ThaiFlag from "../../asset/image/localization/tai.png";
import icon from "../../../src/asset/image/logolotaya.png";
import { BaseURL } from "../../api/apiUrl";
// utils
import { moneyFormatter } from "../../utils/moneyFormatter";
import { removeLocalStorageNoJson } from "../../utils/localStorage";
// config
import { CN, CURRENT_LANG, EN, MM, THAI } from "../../localization/langConfig";
//icons
import { UserCircleIcon } from "@heroicons/react/24/solid";
import LotteryIcon from "../../asset/image/lottery_icon.png";
// context
import { LoginContext } from "../../store/context/LoginProvider";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useEffect } from "react";

import { getProfileApi } from "../../api/profileApi";
import { toastError, toastSuccess } from "../../utils/toast";

import {
  claimPocket,
  claimPromotion,
  isPromoMaintain,
} from "../../api/promotionApi";
import { useConfettie } from "../../store/zustand";
import axios from "axios";

const FlagImage = ({ flagImg, width }) => {
  return (
    <img style={{ width, objectFit: "contain" }} src={flagImg} alt="..." />
  );
};

const WebsiteHeader = ({
  userData,
  handleToggle,
  handleLogout,
  isDisabled,
  marquee,
  loading,
}) => {
  let { t } = useTranslation();
  let { pathname } = useLocation();
  pathname = pathname.substring(1, pathname.length).split("/")[0];
  const navigate = useNavigate();
  const getLang = removeLocalStorageNoJson(CURRENT_LANG);
  const [language, setchangeLanguage] = useState(getLang ? getLang : MM);
  const [openLang, setOpenLang] = useState(false);
  const [openLottery, setOpenLottery] = useState(false);
  const [amount, setAmount] = useState(userData?.balance || 0);
  const [point, setPoint] = useState(userData?.point || 0);
  const [p_balance, setPBalance] = useState(userData?.p_balance || 0);
  const [ploading, setPloading] = useState(false);
  const [promoMaintain, setPromoMaintain] = useState("1");

  const { onConOpen, setShowVD, setPocketAmount } = useConfettie();

  const [
    {
      gameManagement: { category },
    },
  ] = useContext(LoginContext);
  const changeLang = (lang) => {
    i18n.changeLanguage(lang);
    setchangeLanguage(lang);
    setOpenLang(false);
  };
  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (document.visibilityState === "visible") {
        await getProfileApi({ token: userData?.token })
          .then((res) => {
            if (res?.status === 200) {
              setAmount(res?.data?.balance);
              setPoint(res?.data?.point);
              setPBalance(res?.data?.p_balance);
            }
          })
          .catch((err) => {
            throw err;
          });
      } else {
        // The tab is hidden
      }
    };

    setAmount(userData?.balance || 0);
    setPoint(userData?.point || 0);
    setPBalance(userData?.p_balance || 0);

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [
    userData?.balance,
    userData?.token,
    userData?.point,
    userData?.p_balance,
  ]);

  useEffect(() => {
    isPromoMaintain().then((res) => setPromoMaintain(res.is_maintain));
  }, []);

  const handlePromoClaim = useCallback(async () => {
    if (promoMaintain === "1") return;

    setPloading(true);
    try {
      await claimPromotion({ token: userData?.token })
        .then((res) => toastSuccess(res.message))
        .finally(() => setPloading(false));
    } catch (error) {
      const err = await error.json();
      toastError(err.message);
      setPloading(false);
    }
  }, [promoMaintain, userData?.token]);

  const handleGiftBox = useCallback(
    (value) => {
      if (!onConOpen || !setShowVD) return;

      setPocketAmount(value);
      onConOpen();
      setShowVD();
    },
    [onConOpen, setShowVD, setPocketAmount]
  );

  const handlePocketClaim = async () => {
    if (ploading) return;
    setPloading(true);

    await claimPocket({ token: userData?.token })
      .then(async (res) => {
        if (res.status === "success") {
          setPloading(false);

          //fetch user's balance again
          await getProfileApi({ token: userData?.token })
            .then((res) => {
              if (res?.status === 200) {
                setAmount(res?.data?.balance);
                setPoint(res?.data?.point);
                setPBalance(res?.data?.p_balance);
              }
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => setPloading(false));

          handleGiftBox(res.amount);
        }
      })
      .catch(async (err) => {
        setPloading(false);
        const error = await err.json();
        toastError(error.message);
      });
  };

  return (
    <div>
      <div className="d-none border border-0 border-bottom border-secondary col-md-11 mx-auto position-relative d-lg-flex justify-content-end justify-content-md-between align-items-center">
        {/* Lang Switch */}
        <div className="text-warning">
          <Link
            to="/"
            className="d-flex text-decoration-none text-white align-items-center mb-2"
          >
            <img src={icon} style={{ width: "6rem" }} alt="icon" />
          </Link>
        </div>

        <div
          className="d-flex justify-content-end align-items-center pe-3"
          style={{ paddingLeft: "10px" }}
        >
          <div
            onClick={() => setOpenLang(!openLang)}
            className="d-flex cursor-pointer align-items-center px-2"
          >
            {language === EN ? (
              <FlagImage width={35} flagImg={UKFlag} />
            ) : language === MM ? (
              <FlagImage width={35} flagImg={MMFlag} />
            ) : language === CN ? (
              <FlagImage width={35} flagImg={ChinaFlag} />
            ) : (
              <FlagImage width={35} flagImg={ThaiFlag} />
            )}
            {/* Expand Icon */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              fill="#fff"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
            </svg>
          </div>

          <div
            role="button"
            onClick={() => {
              setOpenLottery(!openLottery);
            }}
            className="d-flex align-items-center px-3 py-1 rounded-2 shadow cursor-pointer"
            style={{ backgroundColor: "#C4A732" }}
          >
            <LazyLoadImage
              style={{ width: "30px", objectFit: "contain" }}
              effect="blur"
              src={LotteryIcon}
            />
            <div className="fs-6 ms-2 text-uppercase">
              {t("sectionTwoD.lottery")}
            </div>
          </div>

          {/* Announcement Icon */}
          <img
            width={30}
            height={30}
            src={HeaderIcon}
            alt="..."
            className="ms-3"
          />
        </div>

        {/* Land Switch: DropDown  */}
        <div
          className={`position-absolute ${
            openLang ? "d-flex" : "d-none"
          } flex-column gap-1 bg-white rounded-3 p-2`}
          style={{ left: "1rem", top: 40, zIndex: 9999 }}
        >
          <div
            className={`${
              language !== MM ? "d-flex" : "d-none"
            } gap-2 align-items-center cursor-pointer`}
            onClick={() => changeLang(MM)}
          >
            <FlagImage width={25} flagImg={MMFlag} />
            <span>ဗမာစာ</span>
          </div>
          <div
            className={`${
              language !== EN ? "d-flex" : "d-none"
            } gap-2 align-items-center cursor-pointer`}
            onClick={() => changeLang(EN)}
          >
            <FlagImage width={25} flagImg={UKFlag} />
            <span>English</span>
          </div>
          <div
            className={`${
              language !== THAI ? "d-flex" : "d-none"
            } gap-2 align-items-center cursor-pointer`}
            onClick={() => changeLang(THAI)}
          >
            <FlagImage width={25} flagImg={ThaiFlag} />
            <span>ไทย</span>
          </div>
          <div
            className={`${
              language !== CN ? "d-flex" : "d-none"
            } gap-2 align-items-center cursor-pointer`}
            onClick={() => changeLang(CN)}
          >
            <FlagImage width={25} flagImg={ChinaFlag} />
            <span>中国人</span>
          </div>
        </div>

        {/** marquee */}
        <div className="bs-flex d-flex align-items-center">
          <HeaderMarquee marquee={marquee} />
        </div>

        <div className="d-flex justify-content-end align-items-center">
          {userData?.token ? (
            <div className="d-flex flex-row-reverse ps-3 justify-content-between align-items-center gap-3">
              <div className="dropdown" style={{ cursor: "pointer" }}>
                <UserCircleIcon
                  width={35}
                  className="text-warning"
                  data-bs-toggle="dropdown"
                />
                <ul
                  style={{ zIndex: "10000" }}
                  className="dropdown-menu border border-2 mt-1 border-warning bg-dark rounded-3"
                >
                  <Link to="/profile" className="text-decoration-none ">
                    <li
                      className="profileBtn text-warning ps-3 py-1"
                      style={{ color: "cyan" }}
                    >
                      {t("profile")}
                    </li>
                  </Link>

                  <Link to="/cashin" className="text-decoration-none">
                    <li
                      className="profileBtn text-warning ps-3 py-1"
                      style={{ color: "cyan" }}
                    >
                      {t("cashIn")}
                    </li>
                  </Link>

                  <Link to="/cashout" className="text-decoration-none">
                    <li
                      className="profileBtn text-warning ps-3 py-1"
                      style={{ color: "cyan" }}
                    >
                      {t("cashOut")}
                    </li>
                  </Link>

                  <Link to="/betslip" className="text-decoration-none">
                    <li
                      className="profileBtn text-warning ps-3 py-1"
                      style={{ color: "cyan" }}
                    >
                      {t("betslip")}
                    </li>
                  </Link>

                  <Link
                    to=""
                    onClick={handleLogout}
                    className="text-decoration-none"
                  >
                    <li
                      className="profileBtn text-warning ps-3 py-1"
                      style={{ color: "cyan" }}
                    >
                      {t("logout")}
                    </li>
                  </Link>
                </ul>
              </div>

              <div className="bg-warning bg-opacity-75 rounded-2 p-1 text-center d-flex flex-wrap justify-content-center">
                {/* Main Balance */}
                <svg
                  className="float-start"
                  fill="black"
                  xmlns="http://www.w3.org/2000/svg"
                  height={21}
                  width={21}
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M11.8 10.9c-2.27-.59-3-1.2-3-2.15 0-1.09 1.01-1.85 2.7-1.85 1.78 0 2.44.85 2.5 2.1h2.21c-.07-1.72-1.12-3.3-3.21-3.81V3h-3v2.16c-1.94.42-3.5 1.68-3.5 3.61 0 2.31 1.91 3.46 4.7 4.13 2.5.6 3 1.48 3 2.41 0 .69-.49 1.79-2.7 1.79-2.06 0-2.87-.92-2.98-2.1h-2.2c.12 2.19 1.76 3.42 3.68 3.83V21h3v-2.15c1.95-.37 3.5-1.5 3.5-3.55 0-2.84-2.43-3.81-4.7-4.4z" />
                </svg>
                <span className="px-1 fw-bolder fs-6 font-monospace px-md-3 text-black">
                  {moneyFormatter(parseInt(amount))}
                </span>
              </div>
              {/** Point */}
              <Link to="/point" className="point_div">
                <img
                  src={pointIcon}
                  style={{ width: "2.3rem" }}
                  alt="point"
                  className="point_image"
                />
                <small className="text-nowrap py-1 point_boder">
                  Point - {Math.floor(point)}
                </small>
              </Link>

              {promoMaintain === "0" && (
                <button
                  disabled={ploading}
                  onClick={handlePromoClaim}
                  className={`${
                    ploading && "btn-secondary"
                  } btn btn-warning btn-sm`}
                >
                  Claim - {Math.floor(p_balance)}
                </button>
              )}

              <button
                disabled={ploading}
                onClick={handlePocketClaim}
                className="btn btn-info btn-sm fw-bold"
              >
                Pocket Money
              </button>
            </div>
          ) : (
            <div className="w-100 d-flex gap-3 ps-3">
              <Link
                to="/login"
                className="text-decoration-none d-flex align-items-center justify-content-end"
              >
                <button className="rounded-2 col-12 btn btn-warning border-2 px-3 me-2 mt-1">
                  <span className="fw-bolder">{t("login")}</span>
                </button>
              </Link>
              <Link
                to="/register"
                className="text-decoration-none d-flex align-items-center justify-content-end"
              >
                <button className="rounded-2 col-12 btn btn-dark border-2 px-3 me-2 mt-1">
                  <span className="fw-bolder">
                    {t("registerPage.register")}
                  </span>
                </button>
              </Link>
            </div>
          )}
        </div>
      </div>

      {/**  Category Bar  */}
      <div className="d-none d-md-flex text-center overflow-auto justify-content-center mx-2 align-items-center mt-3">
        <div className="text-warning gap-3 d-inline-flex">
          {category && !loading ? (
            category.length ? (
              category.map((cat, idx) => (
                <div
                  key={idx}
                  onClick={() => {
                    navigate(`${cat.name}`);
                    setOpenLottery(false);
                  }}
                  className={`d-flex align-items-center px-3 py-1 rounded-2 shadow ${
                    pathname.split("%20").join("") ===
                    cat.name.split(" ").join("")
                      ? "bg-warning text-black"
                      : "bg-dark"
                  }  cursor-pointer`}
                >
                  <LazyLoadImage
                    style={{ width: "30px", objectFit: "contain" }}
                    effect="blur"
                    src={`${BaseURL}/${cat.image}`}
                  />
                  <div className="fs-6 ms-2">{cat.name.toUpperCase()}</div>
                </div>
              ))
            ) : (
              <div>no category</div>
            )
          ) : (
            Array.from({ length: 5 }).map((a, idx) => (
              <div
                key={idx}
                style={{ minWidth: "10rem" }}
                className="d-flex gap-2 align-items-center px-2 py-2 h-50 rounded-2 shadow cursor-pointer bg-secondary bg-opacity-75 rounded-5"
              >
                <div
                  style={{ width: "25px", height: "25px" }}
                  className="bg-dark pulse bg-opacity-50 rounded-circle p-2"
                ></div>
                <div
                  style={{ width: "80%", height: "20px" }}
                  className="bg-dark pulse bg-opacity-50 rounded-3 p-2"
                ></div>
              </div>
            ))
          )}
        </div>
      </div>

      <div
        className={`bg-dark rounded-3 ${
          !openLottery && "d-none"
        } border border-2 border-warning h-auto position-absolute`}
        style={{
          left: "17%",
          transform: "translate(-65%,-35%)",
          zIndex: "999999",
          width: "10rem",
        }}
      >
        <ul
          className="mt-1 fw-bolder font-monospace list-unstyled"
          style={{ fontSize: ".96rem" }}
        >
          <Link
            to="/one/lottery"
            onClick={() => setOpenLottery(false)}
            className="text-decoration-none "
          >
            <li
              className="profileBtn text-warning ps-3 py-1"
              style={{ color: "cyan" }}
            >
              {t("oneD")}
            </li>
          </Link>

          <Link
            to="/two/lottery"
            onClick={() => setOpenLottery(false)}
            className="text-decoration-none "
          >
            <li
              className="profileBtn text-warning ps-3 py-1"
              style={{ color: "cyan" }}
            >
              {t("twoD")}
            </li>
          </Link>

          <Link
            to="/crypto/one/lottery"
            onClick={() => setOpenLottery(false)}
            className="text-decoration-none "
          >
            <li
              className="profileBtn text-warning ps-3 py-1"
              style={{ color: "cyan" }}
            >
              Crypto 1D
            </li>
          </Link>

          <Link
            to="/crypto/lottery"
            onClick={() => setOpenLottery(false)}
            className="text-decoration-none "
          >
            <li
              className="profileBtn text-warning ps-3 py-1"
              style={{ color: "cyan" }}
            >
              Crypto 2D
            </li>
          </Link>

          <Link
            to="/three/lottery"
            onClick={() => setOpenLottery(false)}
            className="text-decoration-none "
          >
            <li
              className="profileBtn text-warning ps-3 py-1"
              style={{ color: "cyan" }}
            >
              {t("threeD")}
            </li>
          </Link>

          <Link
            to="/win-number"
            onClick={() => setOpenLottery(false)}
            className="text-decoration-none "
          >
            <li
              className="profileBtn text-warning ps-3 py-1"
              style={{ color: "cyan" }}
            >
              {t("winNoHistory")}
            </li>
          </Link>
          <Link
            to="/winner-list"
            onClick={() => setOpenLottery(false)}
            className="text-decoration-none "
          >
            <li
              className="profileBtn text-warning ps-3 py-1"
              style={{ color: "cyan" }}
            >
              {t("winnerListHistory.title")}
            </li>
          </Link>
        </ul>
      </div>
    </div>
  );
};

export default WebsiteHeader;
